/* eslint-disable */

'use strict';

/*-------------------------------------------------
    Source of this template in root directory
    environment/config.template.js
    this is only example which you may define any structure
---------------------------------------------------*/
/**
 * app configuration from environment
 * @typedef {Object} config
 * @property {Number}       timestamp
 * @property {String}       version
 * @property {Boolean}      production
 * @property {String}       baseUrl
 * @property {String}       apiPath
 * @property {String}       serviceUrl
 * @property {String}       websiteUrl
 */
const riskqConfig = {
    "timestamp": 1741201607846,
    "version": "2.0.1",
    "DEBUG": window.RISKQ_CONFIG.DEBUG,
    "production": window.RISKQ_CONFIG.production,
    "clientTimeFormat": window.RISKQ_CONFIG.clientTimeFormat,
    "idpUrl": window.RISKQ_CONFIG.idpUrl,
    "serviceUrl": window.RISKQ_CONFIG.serviceUrl,
    "websiteUrl": window.RISKQ_CONFIG.websiteUrl,
    "apiPath": window.RISKQ_CONFIG.apiPath,
    "base": window.RISKQ_CONFIG.base,
    "GOOGLE_CLIENT_ID": window.RISKQ_CONFIG.GOOGLE_CLIENT_ID,
    "MICROSOFT_CLIENT_ID": window.RISKQ_CONFIG.MICROSOFT_CLIENT_ID
};

export default riskqConfig;

console.log("Risk-Q Configuration Initialized:", riskqConfig);
